import React from "react"

import { AddressesItem } from "../Item/AddressesItem"

export const AddressesList = ({
  addresses,
  handleDelete,
  handleEdit,
  handleSetDefault,
  loading,
  additionalLoadingText,
  additionalNoAddressText,
}): JSX.Element => (
  <>
    {addresses?.length ? (
      addresses?.map(address => (
        <AddressesItem
          key={address?.id?.toString()}
          address={address}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleSetDefault={handleSetDefault}
          loading={loading}
        />
      ))
    ) : loading ? (
      <p>{additionalLoadingText}</p>
    ) : (
      <p>{additionalNoAddressText}</p>
    )}
  </>
)
