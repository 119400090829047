import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CountryRegionData } from "react-country-region-selector"

export const withAddressesForm = Component => ({ name = "AddressesForm", address, handleChange, handleSubmit, handleCancel, loading, errors }) => {
  const { addressesForm } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_ADDRESSES_FORM {
      addressesForm: sanityPageAccountAddresses {
        additionalSetDefaultAddressButton
        additionalBackToAddressesText
        additionalEditAddressText
        additionalFirstNamePlaceholder
        additionalLastNamePlaceholder
        additionalStreetPlaceholder
        additionalSuburbPlaceholder
        additionalStatePlaceholder
        additionalPostcodePlaceholder
        additionalSaveButton
        additionalCancelButton
      }
    }
  `)

  const {
    additionalSetDefaultAddressButton,
    additionalBackToAddressesText,
    additionalEditAddressText,
    additionalFirstNamePlaceholder,
    additionalLastNamePlaceholder,
    additionalStreetPlaceholder,
    additionalSuburbPlaceholder,
    additionalStatePlaceholder,
    additionalPostcodePlaceholder,
    additionalSaveButton,
    additionalCancelButton,
  } = addressesForm || {}

  const options = address?.country
    ? CountryRegionData.find(region => region[0]?.toLowerCase().trim() === address?.country?.toLowerCase().trim())?.[2]
        ?.split(`|`)
        ?.map(region => ({ label: region.split(`~`)?.[1], value: region.split(`~`)?.[0] }))
    : []

  Component.displayName = name
  return (
    <Component
      address={address}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      loading={loading}
      errors={errors}
      options={options}
      additionalSetDefaultAddressButton={additionalSetDefaultAddressButton}
      additionalBackToAddressesText={additionalBackToAddressesText}
      additionalEditAddressText={additionalEditAddressText}
      additionalFirstNamePlaceholder={additionalFirstNamePlaceholder}
      additionalLastNamePlaceholder={additionalLastNamePlaceholder}
      additionalStreetPlaceholder={additionalStreetPlaceholder}
      additionalSuburbPlaceholder={additionalSuburbPlaceholder}
      additionalStatePlaceholder={additionalStatePlaceholder}
      additionalPostcodePlaceholder={additionalPostcodePlaceholder}
      additionalSaveButton={additionalSaveButton}
      additionalCancelButton={additionalCancelButton}
    />
  )
}
