import tw, { styled } from "twin.macro"

export const Return = styled.button`
  ${tw`w-full absolute -top-4-8 flex flex-row items-center justify-center md:justify-start`}
  span {
    ${tw`font-medium text-14 leading-1.2 tracking-5 uppercase text-red`}
  }
`

export const Form = tw.form`
  relative border-t border-black pt-3-2 border-opacity-50 md:border-opacity-100
`

export const Header = tw.h2`
  font-bold text-20 leading-1.5 tracking-10 mb-3-2 uppercase
`

export const Control = styled.div`
  ${tw`grid grid-cols-2 gap-x-1-6 mt-3-8`}
  button {
    ${tw`px-1`}
  }
`

export const StateWrapper = tw.div`
  grid grid-cols-2 gap-x-1-6 mb-2-4
`

export const Label = tw.label`
  absolute top-0 left-0 text-12 leading-2.08 uppercase tracking-10 text-brown
`
