import React from "react"

import { withAddressesCard } from "./withAddressesCard"
import { AddressesList } from "../List/AddressesList"
import { AddressesForm } from "../Form/AddressesForm"
import { Submit } from "../../Layout/LayoutStyles"

export const AddressesCard = withAddressesCard(
  ({
    address,
    addresses,
    handleAdd,
    handleChange,
    handleDelete,
    handleEdit,
    handleSubmit,
    handleCancel,
    handleSetDefault,
    loading,
    errors,
    additionalLoadingText,
    additionalNoAddressText,
  }): JSX.Element =>
    address?.action?.length ? (
      <AddressesForm
        address={address}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        loading={loading}
        errors={errors}
      />
    ) : (
      <>
        <AddressesList
          addresses={addresses}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleSetDefault={handleSetDefault}
          loading={loading}
          additionalLoadingText={additionalLoadingText}
          additionalNoAddressText={additionalNoAddressText}
        />
        <Submit size={"tertiary"} colour={"black"} onClick={() => handleAdd()}>
          Add Address
        </Submit>
      </>
    )
)
