import React from "react"

import { withAddressesItem } from "./withAddressesItem"
import { Outer, Inner, Address, Text, Control, DefaultBadge, Button } from "./AddressesItemStyles"
import { UnderlinedButton } from "../../../Styled/Button"

export const AddressesItem = withAddressesItem(
  ({
    address,
    handleDelete,
    handleEdit,
    handleSetDefault,
    loading,
    additionalEditAddressButton,
    additionalDeleteAddressButton,
    additionalSetDefaultAddressButton,
    additionalDefaultBadge,
  }): JSX.Element => (
    <Outer>
      <Inner>
        <Address>
          <Text>{address.address1}</Text>
          <Text>
            {address.city} {address.provinceCode}
          </Text>
          <Text>{address.zip}</Text>
        </Address>
        <Control>
          <Button colour={"black-reverse"} title={additionalEditAddressButton} onClick={() => handleEdit(address?.id)} disabled={loading}>
            {additionalEditAddressButton}
          </Button>
          <Button colour={"black-reverse"} title={additionalDeleteAddressButton} onClick={() => handleDelete(address?.id)} disabled={loading}>
            {additionalDeleteAddressButton}
          </Button>
        </Control>
      </Inner>
      {address.default ? (
        <DefaultBadge>{additionalDefaultBadge}</DefaultBadge>
      ) : (
        <UnderlinedButton onClick={() => handleSetDefault(address?.id)} title={additionalSetDefaultAddressButton}>
          <span>{additionalSetDefaultAddressButton}</span>
        </UnderlinedButton>
      )}
    </Outer>
  )
)
