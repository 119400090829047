import React from "react"

import { withAddressesForm } from "./withAddressesForm"
import { TextField } from "../../../TextField/Textfield"
import { Form, Header, Control, StateWrapper, Label, Return } from "./AddressFormStyles"
import { CheckboxInput } from "../../../Checkbox/Checkbox"
import { Icon } from "../../../Icon/Icon"
import { StyledButton } from "../../../Styled/Button"
import { StyledSelectWrapper, StyledDropdownSelect, StyledSelectOption, StyledInputIconWrapper } from "../../../Styled/Form"
import { Errors, Error } from "../../Layout/LayoutStyles"

export const AddressesForm = withAddressesForm(
  ({
    address,
    handleChange,
    handleSubmit,
    handleCancel,
    loading,
    errors,
    options,
    additionalSetDefaultAddressButton,
    additionalBackToAddressesText,
    additionalEditAddressText,
    additionalFirstNamePlaceholder,
    additionalLastNamePlaceholder,
    additionalStreetPlaceholder,
    additionalSuburbPlaceholder,
    additionalStatePlaceholder,
    additionalPostcodePlaceholder,
    additionalSaveButton,
    additionalCancelButton,
  }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      <Return type={"button"} onClick={() => handleCancel()} title={additionalBackToAddressesText}>
        <Icon name={"arrowLeftSmall"} />
        <span>&nbsp;{additionalBackToAddressesText}</span>
      </Return>
      <Header>{additionalEditAddressText}</Header>
      <TextField
        layout={"address"}
        name="firstName"
        type="text"
        value={address?.firstName}
        onChange={handleChange}
        required
        label={additionalFirstNamePlaceholder}
      />
      <TextField
        layout={"address"}
        name="lastName"
        type="text"
        value={address?.lastName}
        onChange={handleChange}
        required
        label={additionalLastNamePlaceholder}
      />
      <TextField
        layout={"address"}
        name="address1"
        type="text"
        value={address?.address1}
        onChange={handleChange}
        required
        label={additionalStreetPlaceholder}
      />
      <TextField
        layout={"address"}
        name="city"
        type="text"
        value={address?.city}
        onChange={handleChange}
        required
        label={additionalSuburbPlaceholder}
      />
      <TextField layout={"address"} name="country" type="text" value={address?.country} onChange={handleChange} required hidden />
      <StateWrapper>
        <StyledSelectWrapper>
          <Label>{additionalStatePlaceholder}</Label>
          <StyledDropdownSelect name="province" value={address?.province} onChange={handleChange} layout={"address"} required>
            <StyledSelectOption value="" disabled></StyledSelectOption>
            {options?.map(option => (
              <StyledSelectOption key={option.label} value={option.value}>
                {option.label}
              </StyledSelectOption>
            ))}
          </StyledDropdownSelect>
          <StyledInputIconWrapper>
            <Icon name={"chevron"} className={`mb-1`} width={"12"} height={"12"} />
          </StyledInputIconWrapper>
        </StyledSelectWrapper>
        <TextField
          layout={"address"}
          name="zip"
          type="text"
          value={address?.zip}
          onChange={handleChange}
          required
          label={additionalPostcodePlaceholder}
        />
      </StateWrapper>
      <CheckboxInput name={"default"} checked={address?.default} onChange={handleChange} title={additionalSetDefaultAddressButton}>
        {additionalSetDefaultAddressButton}
      </CheckboxInput>
      {errors?.length > 0 && (
        <Errors>
          {errors?.map((error, index) => (
            <Error key={index}>
              <p>{error?.message}</p>
            </Error>
          ))}
        </Errors>
      )}
      <Control>
        <StyledButton wide={"true"} type={`submit`} disabled={loading}>
          {additionalSaveButton}
        </StyledButton>
        <StyledButton type={"button"} wide={"true"} colour={"black-reverse"} onClick={() => handleCancel()} disabled={loading}>
          {additionalCancelButton}
        </StyledButton>
      </Control>
    </Form>
  )
)
